import React from "react";

const ProductCard = ({ product }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-500 transform hover:scale-105 hover:shadow-xl">
      {/* Product Image */}
      <img
        src={product.image}
        alt={product.title}
        className="w-full h-60 object-cover"
      />

      {/* Product Info */}
      <div className="p-6">
        <h2 className="text-xl font-semibold text-gray-900">{product.title}</h2>
        <p className="text-gray-700 mt-2">{product.description}</p>

        {/* Price (if needed in the future) */}
        {/* <p className="text-lg font-bold text-gray-800 mt-4">${product.price}</p> */}

        {/* Enquiry Button */}
        <button
          className="mt-6 w-full bg-gradient-to-r from-green-400 to-green-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700 transition duration-300"
          onClick={() => {
            const message =
              "Hello, I'm interested in your windows. Can we discuss further ";
            const phoneNumber = "917755938482"; // Replace with your WhatsApp number
            const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
              message
            )}`;
            window.open(whatsappLink, "_blank"); // Opens WhatsApp with the pre-filled message
          }}
        >
          Enquire Now
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
