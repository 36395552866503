import { Client, ID, Storage } from "appwrite";

// Access environment variables
const appwriteEndpoint = process.env.REACT_APP_APPWRITE_ENDPOINT;
const appwriteProjectId = process.env.REACT_APP_APPWRITE_PROJECT_ID;

// Initialize Appwrite Client
export const client = new Client()
    .setEndpoint("https://cloud.appwrite.io/v1") // Use endpoint from environment variable
    .setProject("670a2bca0034599a4bac"); // Use project ID from environment variable

// Initialize Appwrite Storage
export const storage = new Storage(client);
