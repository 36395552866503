import React from 'react';
import { Icon } from '@iconify/react';

const Header = () => {
  return (
    <header className="bg-[#C0EBA6] p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-xl font-semibold text-gray-800">Khan Furniture</h1>
        <nav>
          <a
            href="tel:+917755938482"
            className="flex items-center space-x-2 p-2 bg-gray-700 bg-opacity-50 text-white rounded-full hover:bg-opacity-80 transition"
          >
            <Icon icon="bi:telephone" style={{ fontSize: '20px' }} />
            <span>Contact Us</span>
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
