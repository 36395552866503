import React, { useState } from 'react';
import { storage } from '../appwrite/appwrite';
import { ID } from 'appwrite';
import { Icon } from '@iconify/react'; // Import Iconify

const FileUploader = () => {
  const [files, setFiles] = useState([]); // State to store the selected files
  const [uploadStatus, setUploadStatus] = useState(null); // Status message
  const [fileCodes, setFileCodes] = useState([]); // Store file codes

  // Function to generate a unique KF code
  const generateKFCode = (length) => {
    const digits = '0123456789';
    let code = 'KF'; // KF prefix
    for (let i = 0; i < length; i++) {
      code += digits[Math.floor(Math.random() * digits.length)];
    }
    return code;
  };

  // Function to handle file selection (multiple files)
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to Array
    setFiles(selectedFiles); // Store all selected files in the state
  };

  // Function to handle file upload (multiple files)
  const handleFileUpload = async () => {
    if (files.length > 0) {
      setUploadStatus('Uploading...');

      try {
        const uploadPromises = []; // To store the upload promises
        const fileCodesArr = []; // To store the generated file codes

        // Loop through each selected file and upload it
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          // Generate a unique KF code for each file
          const uniqueCode = generateKFCode(6); // 6-digit code
          fileCodesArr.push(uniqueCode); // Store the code for later access

          // Log each file being uploaded for debugging purposes
          console.log('Uploading file with code:', uniqueCode);

          // Create the file upload promise for each file using the unique code instead of the file name
          const uploadPromise = storage.createFile(
            '670a2c6c0021ec6c4f2e', // Storage bucket ID
            ID.unique(), // Unique file ID
            new File([file], uniqueCode) // Upload file using the unique code as the file name
          );

          uploadPromises.push(uploadPromise); // Add the promise to the array
        }

        // Wait for all file uploads to finish
        const responses = await Promise.all(uploadPromises);
        setFileCodes(fileCodesArr); // Save the generated codes
        setUploadStatus(`${responses.length} file(s) uploaded successfully!`);
      } catch (error) {
        console.error('Error uploading files:', error); // Handle failure
        setUploadStatus('File upload failed.');
      }
    } else {
      setUploadStatus('No files selected');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform transform hover:scale-105 p-6 max-w-sm mx-auto">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Upload Photos</h2>

      <div className="relative mb-4">
        {/* Custom file input */}
        <input
          type="file"
          accept="image/*" // Restrict to image files
          multiple // Allow multiple file selection
          onChange={handleFileChange}
          className="absolute inset-0 opacity-0 cursor-pointer" // Hide default input
        />
        <button className="w-full flex items-center justify-center border border-gray-300 rounded-lg bg-gray-50 py-2 text-gray-800 hover:bg-gray-200 transition">
          <Icon icon="bi:camera" style={{ fontSize: '20px', marginRight: '8px' }} />
          Upload photos
        </button>
      </div>

      {/* Display selected file names */}
      {files.length > 0 && (
        <div className="mb-4 text-gray-800">
          <h3 className="font-semibold">Selected Files:</h3>
          <ul className="list-disc list-inside">
            {files.map((file, index) => (
              <li key={index} className="text-gray-600">{file.name}</li>
            ))}
          </ul>
        </div>
      )}

      <button
        onClick={handleFileUpload}
        className="w-full bg-[#C0EBA6] text-gray-800 font-semibold py-2 px-4 rounded hover:bg-green-500 transition duration-300"
      >
        Upload
      </button>

      {uploadStatus && <p className="mt-4 text-gray-800">{uploadStatus}</p>}

      {/* Display generated KF codes */}
      {fileCodes.length > 0 && (
        <div className="mt-4">
          <h3 className="text-gray-800 font-semibold">Generated Codes:</h3>
          <ul className="list-disc list-inside">
            {fileCodes.map((code, index) => (
              <li key={index} className="text-gray-600">{code}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
