import React from 'react';
import { Link } from 'react-scroll'; // Library to handle smooth scrolling

const LogoNavigation = () => {
  return (
    <nav className="sticky top-0 bg-white border-lime-400 border-2 rounded-md mb-2 shadow-md py-2 z-50">
      <div className="flex justify-center space-x-6">
        {/* Scroll Links with Underline on Active Section */}
        <Link
          to="Home"
          smooth={true}
          duration={500}
          spy={true}
          offset={-50}
          activeClass="active" // This class is added when the section is in view
          className="cursor-pointer text-sm font-medium text-gray-800 hover:text-green-500 transition-colors"
        >
          <span className="hover:underline">Home</span>
        </Link>
        <Link
          to="windowsSection"
          smooth={true}
          duration={500}
          spy={true}
          offset={-50}
          activeClass="active" // This class is added when the section is in view
          className="cursor-pointer text-sm font-medium text-gray-800 hover:text-green-500 transition-colors"
        >
          <span className="hover:underline">Windows</span>
        </Link>

        {/* <Link
          to="doorsSection"
          smooth={true}
          duration={500}
          spy={true}
          offset={-50}
          activeClass="active"
          className="cursor-pointer text-sm font-medium text-gray-800 hover:text-green-500 transition-colors"
        >
          <span className="hover:underline">Doors</span>
        </Link> */}

        <Link
          to="aboutSection"
          smooth={true}
          duration={500}
          spy={true}
          offset={-50}
          activeClass="active"
          className="cursor-pointer text-sm font-medium text-gray-800 hover:text-green-500 transition-colors"
        >
          <span className="hover:underline">About Us</span>
        </Link>
        <Link
          to="WhyUpvcSection"
          smooth={true}
          duration={500}
          spy={true}
          offset={-50}
          activeClass="active"
          className="cursor-pointer text-sm font-medium text-gray-800 hover:text-green-500 transition-colors"
        >
          <span className="hover:underline">Why UPVC</span>
        </Link>
      </div>
    </nav>
  );
};

export default LogoNavigation;
