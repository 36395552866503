import React from 'react';
import ProductCard from './ProductCard'; // Assuming ProductCard is in the same folder

const upvcProducts = [
  {
    id: 1,
    title: 'UPVC Sliding Window',
    description: 'Premium UPVC sliding windows designed for enhanced insulation and aesthetics.',
    image: 'https://astinenterprises.in/wp-content/uploads/2024/03/upvc-sliding-windows-1700750994-7043950.jpg',
  },
  {
    id: 2,
    title: 'UPVC Casement Door',
    description: 'Durable and sleek UPVC casement doors for modern homes and offices.',
    image: 'https://aparnavenster.com/wp-content/uploads/2024/05/casement-doors.webp',
  },
  {
    id: 3,
    title: 'UPVC French Door',
    description: 'Elegant UPVC French doors that provide style and security.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzLBR389FZxT7C3LdBtWV_84x8StIrbZKt7kz5DwgohYQyli1ohKWU66-wUhmuKI3NqGY&usqp=CAU',
  },
  // Add more products here...
];

const UPVCWorkSection = () => {
  return (
    <section className="bg-gradient-to-b from-white via-gray-50 to-gray-200 py-16">
      <div className="max-w-7xl mx-auto px-6">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Our UPVC Projects</h1>
          <p className="text-xl text-gray-600">
            Discover our premium UPVC installations that combine elegance, durability, and energy efficiency.
          </p>
        </div>

        {/* Product Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {upvcProducts.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default UPVCWorkSection;
