import React, { useState } from "react";
import { Icon } from "@iconify/react";
import FileUploader from "./FileUploader"; // Ensure to import your FileUploader component

const Footer = () => {
    const [admin, setAdmin] = useState(false);
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false); // New state for login status
    const [error, setError] = useState(""); // State for error message

    const openAdmin = () => {
        setAdmin(true); // Open the admin popup
        setError(""); // Reset error message
    };

    const closeAdmin = () => {
        setAdmin(false); // Close the admin popup
    };

    const handleLogin = () => {
        // Check credentials
        if (name === "admin007" && password === "khan909090") {
            setIsLoggedIn(true); // Set login status to true
            setError(""); // Clear any previous error messages
            closeAdmin(); // Close the admin modal
            console.log("Login successful"); // Debugging log
        } else {
            setError("Invalid credentials"); // Set error message
            console.log("Login failed"); // Debugging log
        }
    };

    return (
        <footer className="bg-[#C0EBA6] p-8 shadow-inner">
            <div className="max-w-5xl mx-auto flex justify-between items-center">
                <p onClick={openAdmin} className="text-gray-800 cursor-pointer">
                    © 2024 Khan Furniture
                </p>
                <nav className="flex space-x-4">
                    <a
                        href="https://wa.me/917755938482?text=Hello%2C%20I%20am%20interested%20in%20your%20services"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="WhatsApp"
                        className="p-2 bg-gray-700 bg-opacity-50 text-white rounded-full hover:bg-opacity-80 transition"
                    >
                        <Icon icon="bi:whatsapp" style={{ fontSize: "20px" }} />
                    </a>
                    <a
                        href="https://www.instagram.com/khan_furniture_and_window?igsh=MTc3dGM2empkNXU3ZQ=="
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Instagram"
                        className="p-2 bg-gray-700 bg-opacity-50 text-white rounded-full hover:bg-opacity-80 transition"
                    >
                        <Icon icon="bi:instagram" style={{ fontSize: "20px" }} />
                    </a>
                    <a
                        href="https://facebook.com/yourProfile"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Facebook"
                        className="p-2 bg-gray-700 bg-opacity-50 text-white rounded-full hover:bg-opacity-80 transition"
                    >
                        <Icon icon="bi:facebook" style={{ fontSize: "20px" }} />
                    </a>
                </nav>
            </div>

            {/* Admin Modal */}
            {admin && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-lg font-semibold mb-4">Admin Login</h2>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="border p-2 w-full rounded-md mb-4"
                            placeholder="Enter name"
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border p-2 w-full rounded-md mb-4"
                            placeholder="Enter password"
                        />
                        <div className="flex justify-between">
                            <button
                                onClick={handleLogin}
                                className="bg-green-500 text-white px-4 py-2 rounded-md"
                            >
                                Submit
                            </button>
                            <button
                                onClick={closeAdmin}
                                className="bg-red-500 text-white px-4 py-2 rounded-md"
                            >
                                Close
                            </button>
                        </div>
                        {/* Show error message if login fails */}
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </div>
                </div>
            )}

            {/* Change position of FileUploader */}
            {isLoggedIn && (
                <div className="mt-4 flex justify-center">
                    <FileUploader />
                </div>
            )}
        </footer>
    );
};

export default Footer;
