import React, { useEffect, useState } from 'react';
import { storage } from '../appwrite/appwrite'; // Import Appwrite storage
import { Icon } from '@iconify/react'; // Import Iconify

const WindowShow = () => {
  const [files, setFiles] = useState([]); // State to store files
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedIndex, setSelectedIndex] = useState(null); // State for selected image index
  const [isOpen, setIsOpen] = useState(false); // Modal state

  // Function to fetch files from the storage bucket
  const fetchFiles = async () => {
    try {
      setLoading(true); // Set loading before the API call

      // Get the list of files from the storage bucket
      const response = await storage.listFiles('670a2c6c0021ec6c4f2e'); // Replace with your bucket ID
      console.log('Fetched Files:', response.files); // Debugging: log fetched files

      setFiles(response.files.reverse()); // Store the fetched files in state
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoading(false); // Stop loading after the files are fetched
    }
  };

  // Fetch files on component mount
  useEffect(() => {
    fetchFiles();
  }, []);

  // Function to open the modal with selected file index
  const openModal = (index) => {
    setSelectedIndex(index);
    setIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setSelectedIndex(null);
  };

  // Function to go to the next image
  const nextImage = () => {
    if (selectedIndex !== null && selectedIndex < files.length - 1) {
      setSelectedIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Function to go to the previous image
  const prevImage = () => {
    if (selectedIndex !== null && selectedIndex > 0) {
      setSelectedIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Function to create WhatsApp share link
  const getWhatsAppShareLink = () => {
    const message = `Khan Furniture check out this ${files[selectedIndex]?.name}`; // Customize the message
    return `https://wa.me/917755938482?text=${encodeURIComponent(message)}`; // Create WhatsApp share URL
  };

  return (
    <div className="max-w-7xl mx-auto p-4 ">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Uploaded Window Gallery</h2>

      {loading ? (
        <p className="text-gray-600 text-center">Loading files...</p>
      ) : files.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {files.map((file, index) => (
            <div key={file.$id} className="border border-gray-300 rounded-lg p-2 shadow-md hover:shadow-lg transition-shadow">
              <img
                src={`${storage.getFilePreview('670a2c6c0021ec6c4f2e', file.$id)}`} // Replace with your bucket ID
                alt={file.name}
                className="w-full h-64 object-cover rounded-md mb-2 cursor-pointer" // Fixed height for square shape
                onClick={() => openModal(index)} // Open modal on image click
              />
              <p className="text-sm text-gray-600 text-center truncate">{file.name}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600 text-center">No files found.</p>
      )}

      {/* Modal for displaying full-size image */}
      {isOpen && selectedIndex !== null && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 p-4 overflow-y-auto">
          <div className="relative bg-white rounded-lg shadow-lg max-w-lg w-full max-h-[90vh] overflow-hidden">
            <div className="relative p-4 overflow-y-auto">
              <img
                src={`${storage.getFileView('670a2c6c0021ec6c4f2e', files[selectedIndex].$id)}`} // Replace with your bucket ID
                alt={files[selectedIndex].name}
                className="w-full h-auto max-h-[80vh] object-contain mb-4 rounded-md"
              />
              <p className="text-lg font-semibold text-gray-800 text-center mb-4">
                {files[selectedIndex].name}
              </p>

              {/* WhatsApp share button */}
              <a
                href={getWhatsAppShareLink()}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute top-4 left-4 p-3 bg-green-500 text-white rounded-full flex items-center justify-center hover:bg-green-600 transition"
              >
                <Icon icon="mdi:whatsapp" className="w-6 h-6" />
              </a>

              {/* Navigation buttons centered */}
              <div className="absolute inset-0 flex items-center justify-between p-4">
                <button
                  onClick={prevImage}
                  disabled={selectedIndex === 0}
                  className={`p-3 bg-gray-800 bg-opacity-50 text-white rounded-full focus:outline-none ${selectedIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-70'}`}
                >
                  <Icon icon="mdi:chevron-left" className="w-8 h-8" />
                </button>

                <button
                  onClick={nextImage}
                  disabled={selectedIndex === files.length - 1}
                  className={`p-3 bg-gray-800 bg-opacity-50 text-white rounded-full focus:outline-none ${selectedIndex === files.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-70'}`}
                >
                  <Icon icon="mdi:chevron-right" className="w-8 h-8" />
                </button>
              </div>

              {/* Close button */}
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 p-3 bg-gray-800 bg-opacity-50 text-white rounded-full focus:outline-none"
              >
                <Icon icon="mdi:close" className="w-6 h-6" /> {/* Close icon */}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WindowShow;
