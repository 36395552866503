import React from 'react';
import { Icon } from '@iconify/react';

const AboutUs = () => {
  return (
    <section className="py-12 bg-gray-100" id="aboutUsSection">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-10">About Khan Furniture</h2>
        <p className="text-xl text-center text-gray-600 mb-12">
          Welcome to <span className="font-semibold text-gray-800">Khan Furniture</span> — where tradition meets modern design. With years of experience in craftsmanship, we are proud to create unique, durable, and custom furniture that reflects your personal taste and lifestyle. We don’t just build furniture; we create art that becomes a part of your home.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-12">
          <div className="p-6 bg-white rounded-lg shadow-md">
            <Icon icon="mdi:sofa" className="text-green-600 w-12 h-12 mb-4" />
            <h3 className="text-2xl font-bold text-gray-800 mb-2">Expert Craftsmanship</h3>
            <p className="text-gray-600">
              Every piece is crafted by hand with attention to detail, ensuring quality and durability. We believe in timeless design combined with modern functionality.
            </p>
          </div>

          <div className="p-6 bg-white rounded-lg shadow-md">
            <Icon icon="fluent:design-ideas-16-filled" className="text-green-600 w-12 h-12 mb-4" />
            <h3 className="text-2xl font-bold text-gray-800 mb-2">Custom Designs</h3>
            <p className="text-gray-600">
              We work closely with you to create custom pieces that fit your space and style. Your vision, combined with our expertise, leads to one-of-a-kind furniture.
            </p>
          </div>

          <div className="p-6 bg-white rounded-lg shadow-md">
            <Icon icon="game-icons:ecology" className="text-green-600 w-12 h-12 mb-4" />
            <h3 className="text-2xl font-bold text-gray-800 mb-2">Sustainability First</h3>
            <p className="text-gray-600">
              We use eco-friendly materials wherever possible, ensuring that each piece is not only beautiful but also sustainable for the environment.
            </p>
          </div>

          <div className="p-6 bg-white rounded-lg shadow-md">
            <Icon icon="ri:service-fill" className="text-green-600 w-12 h-12 mb-4" />
            <h3 className="text-2xl font-bold text-gray-800 mb-2">Client-Centered Service</h3>
            <p className="text-gray-600">
              At Khan Furniture, your satisfaction is our top priority. We ensure a seamless process from design to delivery, bringing your dream furniture to life.
            </p>
          </div>
        </div>

        <div className="text-center">
          <button className="mt-6 px-6 py-3 bg-green-500 text-white text-lg font-semibold rounded-lg hover:bg-green-700 transition duration-300">
            Contact Us Today
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
