import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import ProductCard from "../components/ProductCard";
import { storage } from "../appwrite/appwrite";
import UPVCWorkSection from "../components/UpvcWorkSection";
import LogoNavigation from "../components/ScrollNavigation";
import WhyUPVC from "./WhyUPVC";
import WindowGallery from "../components/WindowGallery";
import { useNavigate } from "react-router-dom";
import AboutUs from "./AboutUs";

const images = [
  "https://aparnavenster.com/wp-content/uploads/2022/08/all-that-you-need-to-know-about-upvc-windows-multi-chamber.jpg",
  "https://www.giantsidingandwindows.com/wp-content/uploads/2023/09/why-it-is-important-to-seal-gaps-around-your-windows-01.jpg.webp",
  "https://en.lesso.com/blogs/wp-content/uploads/2021/03/all-you-need-to-know-about-upvc-windows.jpg",
];

const Home = () => {
  const [currentImage, setCurrentImage] = useState(0);
  // console.log("dddd",storage)

  async function getImageLinks() {
    try {
      // Fetch all files from the bucket
      const response = await storage.listFiles("670a2c6c0021ec6c4f2e");

      // Map through the files and generate their URLs
      const imageLinks = response.files.map((file) => {
        return `https://cloud.appwrite.io/v1/storage/buckets/670a2c6c0021ec6c4f2e/files/${file.$id}/view?project=670a2bca0034599a4bac`;
      });

      return imageLinks; // This will be an array of URLs
    } catch (error) {
      console.error("Error fetching image links: ", error);
      return [];
    }
  }
  useEffect(() => {
    getImageLinks().then((links) => {
      console.log("Image Links: ", links);
      // Now you can use these links (e.g., display them or pass them somewhere)
    });
  }, []);

  const products = [
    {
      id: 1,
      title: "Modern Sofa",
      description: "A comfortable and stylish sofa for your living room.",
      price: "499.99",
      image:
        "https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 2,
      title: "Wooden Dining Table",
      description: "A solid wood dining table that seats six.",
      price: "799.99",
      image:
        "https://plus.unsplash.com/premium_photo-1684445034959-b3faeb4597d2?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    // Add more products as needed
  ];

  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 3000); // Auto change image every 3 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const navigate = useNavigate(); // React Router hook for navigation

  // Handle button click to navigate to /calculator
  const handleClick = () => {
    navigate('/calculator');
  };

  return (
    <div className="container mx-auto p-4">
      <LogoNavigation />
      <section id="Home" className="min-h-screen">

      <div className="relative w-full h-80 md:h-96 overflow-hidden rounded-lg shadow-lg">
        <div
          className="relative w-full h-full flex transition-all duration-700 ease-in-out"
          style={{
            transform: `translateX(-${currentImage * 100}%)`, // Move based on currentImage
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              // alt={`Furniture ${index + 1}`}
              className="w-full h-full object-cover"
              style={{ minWidth: "100%" }} // Ensures images take up full width
            />
          ))}
        </div>
        <button
  onClick={handleClick} // Navigate to /calculator when clicked
  className="fixed bottom-24 right-6 z-50 bg-green-500 text-white rounded-full p-4 shadow-lg transform transition-transform duration-300 hover:scale-110 hover:bg-green-600 focus:outline-none flex items-center justify-center"
>
  <span className="mr-2">Calculate Your Price</span>
  <Icon icon="mdi:calculator" className="w-6 h-6" />
</button>


        {/* Left Arrow */}
        {/* <button
          onClick={prevImage}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-80 transition cursor-pointer"
        >
          <Icon icon="akar-icons:chevron-left" width="24" height="24" />
        </button> */}

        {/* Right Arrow */}
        {/* <button
          onClick={nextImage}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-80 transition"
        >
          <Icon icon="akar-icons:chevron-right" width="24" height="24" />
        </button> */}

        {/* Caption */}
        {/* <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-black bg-opacity-40 text-white p-4 rounded-lg">
            <h2 className="text-lg font-semibold">Furniture {currentImage + 1}</h2>
          </div>
        </div> */}
      </div>
      <UPVCWorkSection />
      </section>
      <section id="windowsSection" className="min-h-screen">
        <WindowGallery />
      </section>
      <section id="aboutSection" className="min-h-screen">
        <AboutUs />
      </section>
      {/* <section id="doorsSection" className="min-h-screen bg-gray-100 p-12">
        <h1 className="text-4xl font-bold mb-6">Doors Windows</h1>
        <p>Content about doors...</p>
      </section> */}
      <section id="WhyUpvcSection" className="min-h-screen">
        <WhyUPVC />
      </section>
    </div>
  );
};

export default Home;
