import React, { useEffect, useState } from 'react';
import ProductCard from '../components/ProductCard';

const ProductDetail = () => {
    const products = [
        {
          id: 1,
          title: 'Modern Sofa',
          description: 'A comfortable and stylish sofa for your living room.',
          price: '499.99',
          image: 'https://images.unsplash.com/photo-1578926231185-df2c71d83a15',
        },
        {
          id: 2,
          title: 'Wooden Dining Table',
          description: 'A solid wood dining table that seats six.',
          price: '799.99',
          image: 'https://images.unsplash.com/photo-1575925142100-29e70c2958e2',
        },
        // Add more products as needed
      ];
  return (
    <div className="container mx-auto p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
    {products.map((product) => (
      <ProductCard key={product.id} product={product} />
    ))}
  </div>
  );
};

export default ProductDetail;
