import React from 'react';
import { Icon } from '@iconify/react';

const WhyUPVC = () => {
  const benefits = [
    {
      title: 'Very Durable',
      icon: 'fluent:checkmark-circle-24-filled', // Example icon for durability
      description: 'uPVC products are highly durable, requiring minimal maintenance, and can maintain their appearance for decades, with a lifespan of over 50+ years, reducing environmental impact.',
    },
    {
      title: 'Noise Reduction',
      icon: 'ic:outline-noise-control-off', // Example icon for noise reduction
      description: 'uPVC windows and doors feature a double-seal system combined with insulating glass, reducing noise levels by 30-40 dB, ensuring a quieter environment indoors.',
    },
    {
      title: 'Heavy Wind Resistant',
      icon: 'fa-solid:wind', // Example icon for wind resistance
      description: 'Ideal for seaside locations and high-rise buildings, uPVC windows and doors maintain their strength through fully reinforced galvanized steel inner frames, offering resistance against heavy winds.',
    },
    {
      title: 'Highly Secure',
      icon: 'mdi:lock', // Example icon for security
      description: 'Multi-point high-security locks and fittings make uPVC products robust and secure for both residential and commercial use, with all components securely screwed into the steel core of the frames.',
    },
    {
      title: 'Termite Free',
      icon: 'mdi:bug', // Example icon for termite-free
      description: 'Unlike wooden counterparts, uPVC profiles remain free from termite infestation in all climates, ensuring longevity and structural integrity.',
    },
    {
      title: 'Very Low Maintenance',
      icon: 'mdi:tools', // Example icon for low maintenance
      description: 'uPVC windows require minimal maintenance, eliminating the need for painting, solutions, and liquids for insect prevention, ensuring hassle-free upkeep for all seasons.',
    },
    {
      title: 'Pollution Resistant',
      icon: 'mdi:leaf', // Example icon for pollution resistance
      description: 'Environmentally friendly and 100% lead-free uPVC profiles meet the greenline norms of European countries, ensuring pollution resistance and eco-friendliness.',
    },
    {
      title: 'Energy Saving',
      icon: 'mdi:lightning-bolt', // Example icon for energy saving
      description: 'Insulated Glass Units in uPVC windows can be up to three times more effective than normal glass at maintaining cool temperatures within a building, leading to energy savings.',
    },
  ];

  return (
    <section className="py-12" id="whyUPVCSection">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">Why Choose uPVC?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="p-6 bg-white rounded-lg shadow-lg transition-transform hover:scale-105"
            >
              <Icon
                icon={benefit.icon}
                className="w-12 h-12 mb-4 mx-auto text-green-600"
              />
              <h3 className="text-xl font-semibold text-gray-800">{benefit.title}</h3>
              <p className="mt-2 text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUPVC;
