import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import { storage } from '../appwrite/appwrite'; // Import Appwrite storage

const WindowGallery = () => {
  const [files, setFiles] = useState([]);  // State to store files
  const [selectedIndex, setSelectedIndex] = useState(0); // State for selected image index
  const [loading, setLoading] = useState(true);  // Loading state
  const navigate = useNavigate();  // Use navigate for routing

  // Function to fetch files from the storage bucket
  const fetchFiles = async () => {
    try {
      setLoading(true); // Set loading before the API call

      // Get the list of files from the storage bucket
      const response = await storage.listFiles('670a2c6c0021ec6c4f2e'); // Replace with your bucket ID
      setFiles(response.files.reverse());  // Store the fetched files in state
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoading(false);  // Stop loading after the files are fetched
    }
  };

  // Fetch files on component mount
  useEffect(() => {
    fetchFiles();
  }, []);

  // Automatic slide change every 5 seconds
  useEffect(() => {
    if (files.length > 0) {
      const interval = setInterval(() => {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % files.length);
      }, 5000); // 5 seconds delay

      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [files]);

  // Function to route to '/window' when the component is clicked
  const handleClick = () => {
    navigate('/window');  // Navigate to /window on click
  };

  if (loading) {
    return <p className="text-gray-600 text-center">Loading files...</p>;
  }

  if (files.length === 0) {
    return <p className="text-gray-600 text-center">No files found.</p>;
  }

  return (
    <div
      className="w-full h-screen flex items-center justify-center bg-black cursor-pointer relative"
      onClick={handleClick}  // Trigger navigation on click
    >
      {/* Background Image */}
      <img
        src={`${storage.getFileView('670a2c6c0021ec6c4f2e', files[selectedIndex].$id)}`} // Replace with your bucket ID
        alt={files[selectedIndex].name}
        className="w-full h-full object-cover absolute inset-0"  // Full screen image
      />
      
      {/* Dark overlay for the aesthetic effect */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      
      {/* Centered Heading */}
      <h1 className="text-white text-5xl md:text-7xl font-bold z-10 relative">
        Windows
      </h1>
      
      {/* Navigation instruction text */}
      <p className="text-white text-lg font-light z-10 relative mt-4">
        Click to Explore
      </p>
    </div>
  );
};

export default WindowGallery;
