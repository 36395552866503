import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProductDetail from './pages/ProductDetail';
import PriceCalculator from './pages/PriceCalculator';
import WindowShow from './pages/WindowShow';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductDetail />} />
        <Route path="/calculator" element={<PriceCalculator />} />
        <Route path="/window" element={<WindowShow />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
