import React, { useState } from 'react';
import { Icon } from '@iconify/react'; // Import Iconify for the WhatsApp icon

const trackTypes = [
  { title: '3 Track', rate: 650 },
  { title: '2.5 Track', rate: 550 },
  { title: '2 Track', rate: 450 },
];

const PriceCalculator = () => {
  const [lengthInches, setLengthInches] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [prices, setPrices] = useState({});
  const [error, setError] = useState('');

  const calculatePrice = () => {
    const lengthFeet = parseFloat(lengthInches) / 12;
    const heightFeet = parseFloat(heightInches) / 12;

    if (!isNaN(lengthFeet) && !isNaN(heightFeet) && lengthFeet >= 0.25 && heightFeet >= 0.25) {
      const calculatedPrices = trackTypes.reduce((acc, track) => {
        const totalPrice = lengthFeet * heightFeet * track.rate;
        acc[track.title] = totalPrice.toFixed(2);
        return acc;
      }, {});

      setPrices(calculatedPrices);
      setError('');
    } else {
      setPrices({});
      setError('Please enter valid dimensions of at least 3 inches.');
    }
  };

  const handleInputChange = (setter, value) => {
    const numericValue = parseFloat(value);

    if (value === '' || numericValue >= 3) {
      setError('');
      setter(value);
      setPrices({});
    } else {
      setError('Value must be at least 3 inches.');
      setter(value);
      setPrices({});
    }
  };

  const createWhatsAppShareLink = () => {
    const message = trackTypes
      .map(track => `• ${track.title}: ₹${prices[track.title]}   `)
      .join('%0A');

    const lengthFeet = (parseFloat(lengthInches) / 12).toFixed(2);
    const heightFeet = (parseFloat(heightInches) / 12).toFixed(2);

    return `https://wa.me/?text=Window%20Price%20Calculator%20Results%0ALength: ${lengthFeet} sq-ft%0AHeight: ${heightFeet} sq-ft%0A${message}%0Awww.khanfurniture.in`;
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Window Price Calculator</h1>

      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Length (in inches):</label>
          <input
            type="number"
            step="0.01"
            value={lengthInches}
            onChange={(e) => handleInputChange(setLengthInches, e.target.value)}
            placeholder="Enter length in inches"
            className="border border-gray-300 rounded-md p-2 w-full"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Height (in inches):</label>
          <input
            type="number"
            step="0.01"
            value={heightInches}
            onChange={(e) => handleInputChange(setHeightInches, e.target.value)}
            placeholder="Enter height in inches"
            className="border border-gray-300 rounded-md p-2 w-full"
          />
        </div>

        {error && (
          <div className="text-red-500 mb-4">{error}</div>
        )}

        <button
          onClick={calculatePrice}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200 w-full"
        >
          Calculate Price
        </button>

        {Object.keys(prices).length > 0 && (
          <div className="mt-4 text-lg text-gray-800">
            {trackTypes.map((track) => (
              <div key={track.title}>
                <strong>Total Price for {track.title} ({(lengthInches / 12 * heightInches / 12).toFixed(2)} sq ft):</strong> ₹{prices[track.title]}
              </div>
            ))}

            {/* WhatsApp share button */}
            <a
              href={createWhatsAppShareLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200 w-full text-center flex justify-center items-center space-x-2"
            >
              <Icon icon="logos:whatsapp-icon" width="24" /> {/* WhatsApp Icon */}
              <span>Share via WhatsApp</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceCalculator;
